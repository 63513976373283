.mainPage {
    display: flex;
    /* background-color: #2c2c2e; */
    background-color: black;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    max-width: 820px;
    margin: 0 auto;
}
