.privacy-page_title {
    font-size: 24px;
    text-align: center;
    color: white;
    margin: 0 10px 10px;
    padding-top: 30px;
}

@media (max-width: 768px) {
    .privacy-page_title {
        font-size: 17px;
        font-weight: bold;
    }
  }