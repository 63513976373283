@font-face {
    font-family: 'Montserrat-Light';
    font-weight: 200;
    src: url("./static/Montserrat-Light.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-Regular';
    font-weight: 300;
    src: url("./static/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Montserrat-Bold';
    font-weight: 500;
    src: url("./static/Montserrat-Bold.ttf") format("truetype");
}