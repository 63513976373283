.mainPage__more {
    width: 30px;
    height: 30px;
    transition: opacity 1s, background-color 1s;
    opacity: 0;
    position: absolute;
    bottom: 155px;
    border-radius: 50%;
    transition: opacity 0.7s;
}

.mainPage__more:hover {
    cursor: pointer;
    opacity: 0.7;
}