.navtab__button {
    font-family: 'Inter', Arial, sans-serif;
    ;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    border: none;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.555);
    padding: 0 0 4px;
    margin-top: 24px;
    opacity: 1;
    transition: opacity 1s;
    cursor: pointer;
    text-align: center;
    width: 100%;
}

.navtab__button:hover {
    opacity: 0.5;
}

.navtab__button:nth-child(1) {
    display: flex;
    justify-content: right;
    width: 100%;
}

.navtab__button:nth-child(2) {
    margin: 105px 0 0;
}

@media screen and (max-width: 521px) {
    .navtab__button:nth-child(2) {
        margin: 100px 0 0;
    }
}