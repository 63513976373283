.mainPage__subtitle {
    font-family: 'Montserrat-Light', Arial, Helvetica, sans-serif;
    color: #ffffffe6;
    margin: 0 auto 25px;
    font-size: 15px;
    letter-spacing: 1px;
    margin: 0 auto 25px;
    padding-right: 10px;
    text-align: center;
}

@media (max-width: 768px) {
    .mainPage__subtitle {
        font-size: 15px;
    }
}