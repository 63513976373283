.privacy-page_text {
    max-width: 100%;
    color: white;
    word-wrap: break-word;
    font-size: 14px;
    margin: 10px 10px 0 25px;
}

@media (max-width: 768px) {
    .privacy-page_text {
        font-size: 12px;
    }
  }