.mainPage__info-list {
    font-size: 22px;
    margin: 10px 0;
    padding: 0;
    font-family: 'Open Sans', Arial, sans-serif;
    font-weight: 300;
    text-align: center;
    color: white;
    text-align: left;
    margin: 0;
    padding: 0;
    opacity: 0.7;
}