.mainPage__title {
    color: #ffffffe6;
    text-decoration: none;
    font-family: 'Cormorant_Garamond', 'et-book';
    letter-spacing: 15px;
    font-size: 33px;
    padding: 0 7px 0;
    margin: 80px 0 0;
    text-align: center;
    display: table-cell;
    width: 100vw;
}