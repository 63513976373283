.privacy-page_subtitle {
    font-size: 18px;
    font-weight: bold;
    color: white;
    margin: 30px 10px 0;
}

@media (max-width: 768px) {
    .privacy-page_subtitle {
        font-size: 14px;
    }
}