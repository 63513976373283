.navtab {
    backdrop-filter: blur(10px) opacity(1);
    background-color: transparent;
    background-color: rgba(0, 0, 0, 0.782);
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    left: calc(100vw);
    bottom: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.8s, opacity 0.5s, left 0.8s ease-out;
    z-index: 0;
    max-width: 100vw;
}