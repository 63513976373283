body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url(./fonts/Cormorant_Garamond/Cormorant_Garamond.css);
@import url(./fonts/Inter/inter.css);
@import url(./fonts/Geologica/Geologica.css);
@import url(./fonts/Montserrat/Montserrat.css);

@import url(./components/App/App.css);

@import url(./components/MainPage/style/mainPage.css);
@import url(./components/MainPage/style/mainPage__container.css);
@import url(./components/MainPage/style/mainPage__title.css);
@import url(./components/MainPage/style/mainPage__link-page_Dark.css);
@import url(./components/MainPage/style/mainPage__link-page__description.css);
@import url(./components/MainPage/style/mainPage__more.css);
@import url(./components/MainPage/style/mainPage__more-show.css);
@import url(./components/MainPage/style/mainPage__container-info.css);
@import url(./components/MainPage/style/mainPage__subtitle.css);
@import url(./components/MainPage/style/mainPage__info.css);
@import url(./components/MainPage/style/mainPage__menu.css);
@import url(./components/MainPage/style/mainPage__video.css);
@import url(./components/MainPage/style/mainPage__footer-container.css);
@import url(./components/MainPage/style/mainPage__footer-info.css);
@import url(./components/MainPage/style/mainPage__footer-link.css);
@import url(./components/MainPage/style/mainPage__button.css);
@import url(./components/MainPage/style/mainPage__container-button.css);
@import url(./components/MainPage/style/mainPage__info-list.css);
@import url(./components/MainPage/style/mainPage__info-subtitle.css);
@import url(./components/MainPage/style/mainPage__groupe.css);
@import url(./components/MainPage/style/mainPage__groupe-title.css);
@import url(./components/MainPage/style/mainPage__groupe-list.css);
@import url(./components/MainPage/style/mainPage__list.css);
@import url(./components/MainPage/style/mainPage__under-button.css);
@import url(./components/MainPage/style/mainPage__training.css);
@import url(./components/MainPage/style/mainPage__underline.css);
@import url(./components/MainPage/style/mainPage__additional.css);
@import url(./components/MainPage/style/mainPage__link.css);
@import url(./components/MainPage/style/mainPage__footer-button.css);

@import url(./components/NavTab/style/navtab.css);
@import url(./components/NavTab/style/navtab__button.css);
@import url(./components/NavTab/style/navtab__button-close.css);
@import url(./components/NavTab/style/navtab_visible.css);
@import url(./components/NavTab/style/navtab__link.css);

@import url(./components/MainPage/style/mainPage__footer-title.css);

@import url(./components/PrivacyPage/style/privacy-page.css);
@import url(./components/PrivacyPage/style/privacy-page_text.css);
@import url(./components/PrivacyPage/style/privacy-page_title.css);
@import url(./components//PrivacyPage/style/privacy-page_subtitle.css);
@import url(./components/PrivacyPage/style/privacy-page_text-view.css);